import { useState, useEffect } from 'react';
import './App.css';
import { Storage } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from "./aws-exports";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [fileList, setFileList] = useState([]);

  const bucketName = awsExports.aws_user_files_s3_bucket;

  const fetchFileList = () => {
    setIsLoading(true);
    Storage.list('')
      .then(result => {
        if (result.length > 0) {
          let list = result.map(file => { return ({ key: file.eTag, filename: `public/${file.key}`, size: file.size, staticUrl: `https://${bucketName}.s3.ap-southeast-2.amazonaws.com/public/${file.key}` }) });
          if (list[0].filename === "public/") list.shift(); // top-level folder
          setFileList(list);
        }
        setIsLoading(false);
      })
      .catch(err => console.log(err));
  }

  useEffect(() => {
    fetchFileList();
  }, []);

  return (
    <Authenticator>
      {({ signOut }) => (
        <div style={styles.container}>
          <h1>Intopia Static Assets</h1>
          <div>{isLoading && 'Loading'}</div>
          <ul>
            {!isLoading && 
              fileList.map(file => <li key={file.key}><a href={file.staticUrl}>{file.filename}</a></li>)
            }
          </ul>
          <button style={styles.button} onClick={signOut}>Sign out</button>
        </div>
      )}
    </Authenticator>
  );
}

const styles = {
  container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

export default App;
